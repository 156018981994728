export default class User {
    constructor(id, email, password, password_confirmation, nome) {
        this.id = id;
        this.email = email;
        this.password = password;
        this.password_confirmation = password_confirmation;
        this.nome = nome;

        this.cliente = null;
        this.roleNames = [];
    }
} 


