<template>
  <section class="register-2">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div
          class="content-wrapper d-flex align-items-stretch auth auth-img-bg"
        >
          <div class="row flex-grow">
            <div
              class="col-lg-6 d-flex align-items-center justify-content-center"
            >
              <div class="auth-form-transparent text-left p-3">
                <div class="brand-logo">
                  <img src="@/assets/images/piattino-logo.png" alt="logo" />
                </div>
                <h4>Novo por aqui?</h4>
                <h6 class="font-weight-light">
                  Junte-se a nós hoje! Preencha os dados a seguir:
                </h6>
                <b-alert :show="message != ''" variant="danger">{{
                  message
                }}</b-alert>
                <form class="pt-3" @submit.stop.prevent="handleRegister">
                  <b-form-group label="Nome" label-for="nomeInput">
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-account-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="nomeInput"
                        type="text"
                        placeholder="Nome"
                        v-model="$v.user.nome.$model"
                        :state="
                          $v.user.nome.$dirty ? !$v.user.nome.$error : null
                        "
                        aria-describedby="nome-live-feedback"
                        maxlength="255"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback id="nome-live-feedback">
                        <template v-if="!$v.user.nome.required"
                          >Preenchimento obrigatório</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label="Email" label-for="emailInput">
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-email-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="emailInput"
                        type="text"
                        placeholder="Email"
                        v-model="$v.user.email.$model"
                        :state="
                          $v.user.email.$dirty ? !$v.user.email.$error : null
                        "
                        aria-describedby="email-live-feedback"
                        maxlength="255"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback id="email-live-feedback">
                        <template v-if="!$v.user.email.required"
                          >Preenchimento obrigatório</template
                        >
                        <template v-if="!$v.user.email.email"
                          >Email inválido</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label="Senha" label-for="passwordInput">
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="passwordInput"
                        type="password"
                        placeholder="Senha"
                        v-model="$v.user.password.$model"
                        :state="
                          $v.user.password.$dirty
                            ? !$v.user.password.$error
                            : null
                        "
                        aria-describedby="password-live-feedback"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback id="password-live-feedback">
                        <template v-if="!$v.user.password.required"
                          >Preenchimento obrigatório</template
                        >
                        <template v-if="!$v.user.password.minLength"
                          >Senha deve ter
                          {{ $v.user.password.$params.minLength.min }} ou mais
                          caracteres</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    label="Confirmar Senha"
                    label-for="passwordConfirmationInput"
                  >
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="passwordConfirmationInput"
                        type="password"
                        placeholder="Confirmar Senha"
                        v-model="$v.user.password_confirmation.$model"
                        :state="
                          $v.user.password_confirmation.$dirty
                            ? !$v.user.password_confirmation.$error
                            : null
                        "
                        aria-describedby="password_confirmation-live-feedback"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="password_confirmation-live-feedback"
                      >
                        <template
                          v-if="!$v.user.password_confirmation.sameAsPassword"
                          >Senhas não conferem</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>

                  <!-- <div class="mb-4">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input"> Eu concordo com todos os Termos & Condições </label>
                    </div>
                  </div> -->
                  <div class="mt-3">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="btn-block font-weight-medium auth-form-btn"
                      :disabled="loading"
                    >
                      <template v-if="loading">
                        <b-spinner small type="grow"></b-spinner>
                        Registrando...
                      </template>
                      <template v-else> REGISTRAR </template>
                    </b-button>
                  </div>
                  <div class="text-center mt-4 font-weight-light">
                    Já possui uma conta?
                    <router-link :to="{ name: 'Login' }" class="text-center"
                      >Login</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 register-half-bg d-flex flex-row">
              <p
                class="
                  text-white
                  font-weight-medium
                  text-center
                  flex-grow
                  align-self-end
                "
              >
                Copyright &copy;
                <a href="https://piattino.digital/" target="_blank"
                  >Piattino
                </a>
                2021 Todos os direitos reservados.
              </p>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import User from "@/models/user";

export default {
  name: "Register",
  data() {
    return {
      user: new User(),
      loading: false,
      message: "",
    };
  },
  methods: {
    handleRegister() {
      this.message = "";
      this.loading = true;
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        this.loading = false;
        return;
      }

      if (this.user.email && this.user.password) {
        this.$store.dispatch("auth/register", this.user).then(
          () => {
            this.$store.dispatch("auth/login", this.user).then(
              () => {
                this.$router.push({name: "EmailVerificar"});
              },
              (error) => {
                this.loading = false;
                if (error.response) {
                  this.message = error.response.data.message;
                }
              }
            );

          },
          (error) => {
            this.loading = false;
            if (error.response) {
              this.message = error.response.data.message;
            }
          }
        );
      }
    },
  },
  validations: {
    user: {
      email: {
        required,
        email,
        maxLength: maxLength(255),
      },
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
      nome: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
